@import "styles/utils";
@import "styles/font_variables";

.footer {
  @include flex-column;
  margin: 0 auto 44px;
  align-items: center;
  border-top: 0.5px solid #6b72807c;
  padding-top: 80px;
  max-width: 1224px;

  @media (max-width: 768px) {
    margin: 0 20px 44px 20px;
    align-items: center;
    text-align: center;
  }

  .content {
    display: flex;
    justify-content: space-between;
    margin: 0px 0px 84px;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      margin: 0px 0px 40px;
    }

    .signature {
      @include flex-column;
      position: relative;
      flex: 0 1 251px;

      @media (max-width: 768px) {
        align-items: center;
        margin-bottom: 40px;
        margin-top: 50px;
        flex: 0 1 0;
      }

      .logo {
        margin-bottom: 23px;
        img {
          max-width: 100px;
          width: 100%;
        }
      }

      .desc {
        overflow: visible;
        @include font-face($font-poppins-400-13px-1_24, #6b7280);

        @media (max-width: 768px) {
          text-align: center;
        }
      }
    }

    .navigation {
      @include flex-column;
      flex-direction: row;
      gap: 82px;

      @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        gap: 30px;
      }

      .footer_column {
        @include flex-column;
        margin: 16px 0px 2px;

        @media (max-width: 768px) {
          align-items: center;
        }

        .title {
          @include font-face($font-poppins-700-24px-1_24, rgb(17, 24, 39), 0px);
          margin-bottom: 22px;
        }

        .item {
          @include font-face($font-poppins-500-18px-1_24, rgb(107, 114, 128), 0px);
          margin-top: 12px;
          text-decoration: none;

          &.emphasized {
            @include font-face($font-poppins-500-18px-1_24, rgb(94, 98, 130), 0px);
          }
        }
      }
    }
  }

  .copyright {
    display: flex;
    justify-content: flex-end;
    font: $font-poppins-500-14px-1_24;
    color: rgb(107, 114, 128);
    text-align: center;
    margin: 0px auto;

    @media (max-width: 768px) {
      justify-content: center;
      margin-top: 20px;
    }
  }
}
