.cropper-zoom-slider {
  input::-webkit-slider-thumb {
    -webkit-appearance: none; 
    appearance: none;
    height: 12px;
    width: 12px;
    border-radius: 100%;
    background: #7E7E7E;
  }
  .slider::-moz-range-thumb {
    height: 12px;
    width: 12px;
    border-radius: 100%;
    background: #7E7E7E;
  }
}